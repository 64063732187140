<template>
    <div :class="{visibled_emulator: $store.state.visibledEmulator}" class="container">
    <div class="title">
        <h1>
            {{ titleMap[category][course] }}
        </h1>
    </div>
    <div class="contents">
        <div v-html="compileMarkdown"></div>
    </div>
  </div>
</template>

<script>
import { Marked } from 'marked'
import { markedHighlight } from "marked-highlight";
import hljs from 'highlight.js'
import markedAlert from 'marked-alert'


export default {

  components: {
  },
  data () {
    return {
      category: '',
      course: '',
      page: '',
      mdContents: '',
      titleMap: { 
        "linux": {
          "filesystem": "ファイルの操作",
          "user_group": "ユーザーとグループ"
        }
      }
    }
  },
  methods: { 
    refreshStage: function () {
      const xhr= new XMLHttpRequest()
      xhr.onload = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            this.mdContents = xhr.response
          } else {
            this.mdContents = ""
            console.log("Fetch markdown error")
          }
        }
      }
      xhr.open('GET', '/contents/'+this.category+'/'+this.course+'/'+this.page+'.md')
      xhr.send()
    }
  },
  computed: {
    compileMarkdown: function () {
      const marked = new Marked(
        markedHighlight({
          langPrefix: 'hljs language-',
          highlight(code, lang) {
            const language = hljs.getLanguage(lang) ? lang : 'plaintext';
            return hljs.highlight(code, { language }).value;
          }
        })
      )
      marked.use(markedAlert())
      return marked.parse(this.mdContents)
    }
  },
  created () {
    this.category = this.$route.params.category
    this.course = this.$route.params.course
    this.page = this.$route.params.page
    this.refreshStage()
  },
  watch: {
    $route(to) {
      const path = to["path"].split("/")
      if (path.length === 5) {
          this.category = path[2]
          this.course = path[3]
          this.page = path[4]
          this.refreshStage()
      } else {
          location.href = "/"
      }
    }
  }
}
</script>

<style>
.markdown-alert {
  align-items: center;
  padding-top: 1rem;
  padding-left: 1rem;
  margin-bottom:24px;
  box-shadow: 0 0 5px #999;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: #333;
  gap: 0.5rem;
  line-height: 1;
}

.markdown-alert-title svg{
  margin-right: 10px;
}

.markdown-alert-note {
  border-left: 5px solid #5CBD9D;
 }
.markdown-alert-caution {
  border-left: 5px solid #E74C3C;
 }
.markdown-alert-warning {
  border-left: 5px solid #F29C33;
 }
.markdown-alert-tip {
  border-left: 5px solid #3998DB;
 }

</style>

<style scoped>
.visibled_emulator {
  margin-bottom: 320px;
}

.title {
  margin-top: 24px;
  margin-bottom: 24px;
}

.contents {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: left;
}
</style>
