<template>
  <div>
    <div class="navbar navbar-expand-sm navbar-light bg-light py-1">
      <div class="collapse navbar-collapse">
        <div class="navbar-nav ml-auto">
          <div class="navbar-brand mx-2">
              <router-link class="text-dark pr-2 d-flex align-items-center" style="text-decoration: none" to="/"><img src="/favicon.png" width="32" /><div class="pl-1">CELTF入門</div></router-link>
          </div>
        </div>
        <div class="navbar-nav mx-auto">
          <form class="d-flex">
            <div class="input-group">
              <input v-model="flag" size=40 class="mr-sm-1" placeholder="Flag" />
              <button v-bind:disabled="isToppage" size="sm" class="btn btn-secondary" @click.prevent="confirmFlag">フラグを検証</button>
            </div>
          </form>
		</div>
        <div class="navbar-nav mr-auto">
          <button v-bind:disabled="isToppage" size="sm" class="btn btn-secondary mx-2" @click="launchEmulator">LinuxVMを起動</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  props: {
    pFlag: String,
  },
  data () {
    return {
      flag: this.pFlag,
      isToppage: true,
      flagMap: {
        "linux": {
          "filesystem": [
            "4bd572f4-7d26-41f9-bb2b-4e332e2088f8",
            "cc6c4afc-54bb-412f-8f4f-b54888e05556",
            "eff25232-f50b-5f19-a29e-b5125ced4482",
            "2fbb311e-04aa-49ad-9fc8-399adf6204a9",
            "558334fe-cb4c-5a46-a3e5-d9e64632100e"
          ],
          "user_group": [
            "2260136d-032d-4d58-8832-82589bfe4398",
            "74f86718-6878-4b90-9cc6-88159eca203d",
            "d7cea161-803d-47f7-861b-27fabdbd2e9f",
            "eada88ec-23c1-5114-9016-a45f84eaace7",
            "19defa37-1695-5791-81e5-78903f1b8bcd"
          ]
        }
      }
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
    async confirmFlag () {
        const path = location.pathname.split("/")
        if (path.length === 5) {
            const currentFlag = this.flag
            this.flag = ''
            const category = path[2]
            const course = path[3]
            const page = path[4]
            if (currentFlag === this.flagMap[category][course][Number(page)-1]){
                if (this.flagMap[category][course].length === Number(page)){
                  this.$swal({
                    title: '正しいフラグが入力されました！',
                    text: '全てのページが完了しました！',
                    type: 'success',
                    icon: 'success',
                    confirmButtonText: 'トップページへ'
                  }).then(() => {
                      location.href = '/'
                  })
                } else {
                  this.$swal({
                    title: '正しいフラグが入力されました！',
                    type: 'success',
                    icon: 'success',
                    confirmButtonText: '次のページへ'
                  }).then(() => {
                      const nextPage = Number(page)+1
                      this.$router.push({
                          path: '/stage/'+category+'/'+course+'/'+nextPage.toString(),
                          replace: true
                      })
                      //location.href = '/stage/'+category+'/'+course+'/'+nextPage.toString()
                  })
                }
             } else {
                this.$swal({
                  title: '入力されたフラグは不正でした！',
                  type: 'error',
                  icon: 'error'
                })
              }
        }
    },
    async launchEmulator () {
      this.$store.commit("launchEmulator")
    },
  },
  watch: {
    $route(to) {
      if (to["path"] === "/" && (to["hash"] === "" || to["hash"] === "#/")) {
          this.isToppage = true
          this.$store.commit("hideEmulator")
      } else {
          this.isToppage = false
      }
    }
  }
}
</script>
<style>

</style>
